import React, { useContext, memo } from 'react'
import { CurrentUserContext } from '../../../context/Shared/CurrentUser.context'
import Avatar from '@mui/material/Avatar'
import { theme } from '../../../styles/theme.style'
import { getFirstName } from '../../../utils/functions'
import { useTranslation } from 'react-i18next'
import { SideBarContext } from '../../../context/Shared/SideBar.context'
import { SelectPortal } from '../SelectPortal/SelectPortal.component'
import { AiFillCloseCircle } from 'react-icons/ai'

import styles from './SideBar.module.scss'

const TopSideBar = () => {
  const { me } = useContext(CurrentUserContext)
  const { isTabletOrMobile, setSideBarVisibility } = useContext(SideBarContext)
  const { t } = useTranslation()

  return (
    <>
      <div className={styles.drawerHeader}>
        {isTabletOrMobile && (
          <button
            className={styles.closeButton}
            onClick={() => setSideBarVisibility(false)}
          >
            <AiFillCloseCircle className={styles.closeButton__icon} />
          </button>
        )}
        <Avatar
          className={styles.avatar}
          src={theme.PROFILE}
          sx={{ width: 170, height: 170, bgcolor: theme.COLOR_LIGHT_BLUE }}
        />
      </div>
      <div className={styles.topContainer}>
        <div className={styles.drawerHeaderBold}>
          {t('Welcome')} {me ? getFirstName(me.name) : ''}
        </div>
      </div>
      {isTabletOrMobile && (
        <div className={styles.selectPortal}>
          <SelectPortal />
        </div>
      )}
    </>
  )
}

export default memo(TopSideBar)
