import React from 'react'
import { FaVideo } from 'react-icons/fa'
import styles from './VideoSlide.module.scss'

const VideoSlide = ({ contentUrl, datakey }) => (
  <div className={styles.videoContainer}>
    <FaVideo className={styles.videoContainer__videoIcon} />
    <video src={contentUrl} datakey={datakey} alt="draggable video file" />
  </div>
)

export default VideoSlide
