import React, { useEffect } from 'react'
import io from 'socket.io-client'
import { getJWT, initiateAnalytics, isScreenVU } from '../../../utils/functions'
import { CurrentUserContext } from '../../../context/Shared/CurrentUser.context'
import { CurrentPortalContext } from '../../../context/Shared/CurrentPortal.context'
import { pathNameMap } from '../../../utils/constants'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { NotificationContext } from '../../../context/Shared/Notification.context'
import { theme } from '../../../styles/theme.style'

const ENDPOINT = process.env.REACT_APP_BACKEND

const Socket = ({
  setSocket,
  setOnline,
  setOnlineStatus,
  setIncomingCall,
  setCallFrom,
  setIncomingAddToCall,
  goToSession,
  signOut,
  location,
  setUserBeingCalledId
}) => {
  const { me } = React.useContext(CurrentUserContext)
  const { portal } = React.useContext(CurrentPortalContext)
  const { t } = useTranslation()
  const { setSnackBarMessage } = React.useContext(NotificationContext)

  useEffect(async () => {
    const socket = io.connect(ENDPOINT, {
      auth: {
        userId: me.email,
        userType: isScreenVU(me) ? 'ScreenVU' : 'Operator',
        token: await getJWT(),
        portal: portal.name,
        name: me.name,
        recordingMode: me.recordingMode,
        preferredVideoCodec: me.preferredVideoCodec,
        preferredVideoResolution: me.preferredVideoResolution
      },
      withCredentials: true
    })

    setSocket(socket)

    socket.on('connect_error', (err) => {
      console.log(`connect_error due to ${err.message}`)
      setOnline({})
      setOnlineStatus(false)
      if (err.message === 'Authentication error') {
        window.location.reload(false)
      }
    })

    socket.on('user list', (data) => {
      setOnline(data)
      setOnlineStatus(true)
    })

    socket.on('initiate call', (data) => {
      if (pathNameMap[location.pathname] !== theme.SESSION) {
        if (isScreenVU(me) || me.autoAcceptCalls) {
          const id = uuidv4()
          const to = data.from.socketId
          socket.emit('accept call', {
            id,
            to
          })
          goToSession(id)
        } else {
          setIncomingCall(true)
          setCallFrom(data)
        }
      }
    })

    socket.on('accept call', (data) => {
      setSnackBarMessage(t('Call accepted. Connecting you...'))
      setTimeout(() => {
        goToSession(data.id)
      }, 500)
      setUserBeingCalledId(null)
    })

    socket.on('add to call', (data) => {
      if (pathNameMap[location.pathname] !== theme.SESSION) {
        if (isScreenVU(me) || me.autoAcceptCalls) {
          goToSession(data.id)
        } else {
          setIncomingAddToCall(true)
          setCallFrom(data)
        }
      }
    })

    socket.on('reject call', () => {
      setSnackBarMessage(t('Call rejected'), 'error')
      setUserBeingCalledId(null)
    })

    socket.on('logout', () => {
      setSnackBarMessage(t('You have logged in on another device'), 'info')
      if (socket) {
        socket.disconnect()
      }

      signOut()
    })

    initiateAnalytics(me.id, {
      email: me.email,
      name: me.name,
      portal: portal.name,
      role: portal.role
    })

    return () => {
      socket.disconnect()
      socket.off('connect')
      socket.off('disconnect')
      socket.off('connect_error')
      socket.off('user list')
      socket.off('initiate call')
      socket.off('accept call')
      socket.off('add to call')
      socket.off('reject call')
      socket.off('logout')
    }
  }, [portal.name, me])

  return null
}

Socket.propTypes = {
  setSocket: PropTypes.func,
  setOnline: PropTypes.func,
  setOnlineStatus: PropTypes.func,
  setIncomingCall: PropTypes.func,
  setCallFrom: PropTypes.func,
  setIncomingAddToCall: PropTypes.func,
  goToSession: PropTypes.func,
  signOut: PropTypes.func,
  location: PropTypes.object,
  setUserBeingCalledId: PropTypes.func
}

export default React.memo(Socket)
