/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'
import { Auth } from 'aws-amplify'
import { PasswordInput } from '../PasswordInput/PasswordInput'
import { PasswordCheck } from '../PasswordCheck/PasswordCheck'
import { containsEmail } from '../../../utils/functions'
import { useTranslation } from 'react-i18next'
import Checkbox from '@mui/material/Checkbox'
import { NotificationContext } from '../../../context/Shared/Notification.context'
import { theme } from '../../../styles/theme.style'
import { BrandedButton } from '../../Shared/BrandedButton/BrandedButton.component'

import './SignInForm.style.scss'

export const SignInForm = ({ prefilledEmail }) => {
  const { t, i18n } = useTranslation()
  const [email, setEmail] = useState(prefilledEmail)
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [userErrorText, setUserErrorText] = useState('')
  const [passwordErrorText, setPasswordErrorText] = useState('')
  const [newPasswordErrorText, setNewPasswordErrorText] = useState('')
  const [user, setUser] = useState(null)
  const [showDefault, setShowDefault] = useState(true)
  const [showPasswordReset, setShowPasswordReset] = useState(false)
  const [showToken, setShowToken] = useState(false)
  const [code, setCode] = useState('')
  const [challengeName, setChallengeName] = useState(null)
  const [isAccepted, setIsAccepted] = useState(false)
  const { setSnackBarMessage } = useContext(NotificationContext)

  const signIn = async () => {
    try {
      const cUser = await Auth.signIn(email.trim(), password.trim())
      if (cUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setUser(cUser)
        setShowDefault(false)
        setShowPasswordReset(true)
        setShowToken(false)
      }
      if (
        cUser.challengeName === 'SMS_MFA' ||
        cUser.challengeName === 'SOFTWARE_TOKEN_MFA'
      ) {
        setUser(cUser)
        setShowDefault(false)
        setShowPasswordReset(false)
        setChallengeName(cUser.challengeName)
        setShowToken(true)
      }
    } catch (err) {
      setSnackBarMessage(err.message, 'error')
    }
  }

  const completeNewPassword = async () => {
    try {
      const cUser = await Auth.completeNewPassword(user, newPassword)
      if (
        cUser.challengeName === 'SMS_MFA' ||
        cUser.challengeName === 'SOFTWARE_TOKEN_MFA'
      ) {
        setUser(cUser)
        setShowDefault(false)
        setShowPasswordReset(false)
        setChallengeName(cUser.challengeName)
        setShowToken(true)
      }
    } catch (err) {
      setSnackBarMessage(err.message, 'error')
    }
  }

  const confirmSignIn = async () => {
    try {
      const cUser = await Auth.confirmSignIn(user, code, challengeName)
    } catch (err) {
      setSnackBarMessage(err.message, 'error')
    }
  }

  useEffect(() => {
    if (email.length > 0) {
      if (!containsEmail(email)) {
        setUserErrorText('Please enter a valid email address')
      } else {
        setUserErrorText('')
      }
    }
  }, [email])

  return (
    <div>
      {showDefault && (
        <div className="loginForm">
          <TextField
            id="outlined-name"
            label={t('Email')}
            className="textInput"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            error={userErrorText.length > 0}
            helperText={userErrorText}
            sx={{ marginBottom: 2 }}
          />
          <PasswordInput
            label={t('Password')}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                signIn()
              }
            }}
            error={passwordErrorText.length > 0}
            helperText={passwordErrorText}
            sx={{ marginBottom: 2 }}
          />
          <BrandedButton
            onClick={signIn}
            disabled={
              email.length === 0 ||
              password.length === 0 ||
              userErrorText.length > 0
            }
            buttonLabel={'Login'}
          />
        </div>
      )}
      {showPasswordReset && (
        <div className="loginForm">
          <p>{t('Select a new password')}</p>
          <PasswordInput
            label={t('New Password')}
            value={newPassword}
            onChange={(event) => setNewPassword(event.target.value)}
            error={newPasswordErrorText.length > 0}
            helperText={newPasswordErrorText}
            sx={{ marginBottom: 2 }}
          />
          <PasswordCheck password={newPassword} />
          <div className="loginForm__checkbox">
            <Checkbox
              checked={isAccepted}
              onChange={(event) => setIsAccepted(event.target.checked)}
            />
            <p>
              I agree to the &nbsp;
              <a href={theme.TERMS_OF_SERVICE} target="_blank" rel="noreferrer">
                Terms of Use
              </a>
              &nbsp; and &nbsp;
              <a href={theme.PRIVACY_POLICY} target="_blank" rel="noreferrer">
                Privacy Policy
              </a>
            </p>
          </div>
          <BrandedButton
            onClick={completeNewPassword}
            disabled={
              newPassword.length === 0 ||
              newPasswordErrorText.length > 0 ||
              !isAccepted
            }
            buttonLabel={'Change Password'}
          />
        </div>
      )}
      {showToken && (
        <div className="loginForm">
          <p>
            {t('Please enter your verification code')}{' '}
            {challengeName === 'SOFTWARE_TOKEN_MFA'
              ? t('(Authenticator App Based)')
              : t('(SMS Based)')}
          </p>
          <TextField
            id="outlined-name"
            label={t('Verification Code')}
            className="textInput"
            value={code}
            onChange={(event) => setCode(event.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <BrandedButton
            onClick={confirmSignIn}
            disabled={code.length === 0}
            buttonLabel={'Confirm'}
          />
        </div>
      )}
    </div>
  )
}

SignInForm.propTypes = {
  prefilledEmail: PropTypes.string
}
