import React from 'react'
import Button from '@mui/material/Button'
import CallIcon from '@mui/icons-material/Call'
import CallEndIcon from '@mui/icons-material/CallEnd'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import './IncomingCall.scss'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
}

export default function IncomingCall ({
  callFrom,
  incomingCall,
  setIncomingCall,
  acceptCall,
  rejectCall,
  addFlag
}) {
  const [audio] = React.useState(new Audio(process.env.PUBLIC_URL + 'call.mp3'))
  const { t } = useTranslation()
  let timer

  React.useEffect(() => {
    if (incomingCall) {
      audio.currentTime = 0
      audio.loop = true
      audio.play()
      timer = setTimeout(() => reject(), 45000)
    } else {
      clearTimeout(timer)
    }
  }, [incomingCall])

  React.useEffect(() => {
    return () => clearTimeout(timer)
  }, [])

  function accept () {
    audio.pause()
    if (addFlag) {
      acceptCall(callFrom.id)
    } else {
      acceptCall(callFrom.from.socketId)
    }
    setIncomingCall(false)
  }

  function reject () {
    audio.pause()
    rejectCall(callFrom.from.socketId)
    setIncomingCall(false)
  }

  return (
    <Modal open={incomingCall}>
      <Box sx={style}>
        <div className="incomingCall">
          <div className="incomingCall__content">
            <p className="incomingCall__content__text">
              {t('Incoming call from')}
              <br />
              {addFlag
                ? callFrom && <span>{callFrom.from.name}</span>
                : callFrom &&
                  (callFrom.from.userType === 'Device'
                    ? (
                    <span>{callFrom.from.userId}</span>
                      )
                    : (
                    <span>{callFrom.from.name}</span>
                      ))}
            </p>
            <div className="incomingCall__content__buttons">
              <Button
                className="incomingCall__content acceptCall"
                startIcon={<CallIcon />}
                onClick={() => accept()}
              >
                {t('Accept')}
              </Button>
              <Button
                className="incomingCall__content rejectCall"
                startIcon={<CallEndIcon />}
                onClick={() => reject()}
              >
                {t('Reject')}
              </Button>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  )
}

IncomingCall.propTypes = {
  callFrom: PropTypes.string,
  incomingCall: PropTypes.bool,
  setIncomingCall: PropTypes.func,
  acceptCall: PropTypes.func,
  rejectCall: PropTypes.func,
  addFlag: PropTypes.bool
}
