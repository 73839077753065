import React, { createContext, useState, useEffect, useContext } from 'react'
import { CurrentUserContext } from '../Shared/CurrentUser.context'
import { CurrentPortalContext } from '../Shared/CurrentPortal.context'

import { getJWT } from '../../utils/functions'

import io from 'socket.io-client'
import { NotificationContext } from '../Shared/Notification.context'

export const ChatSocketContext = createContext(null)

export const ChatSocketContextProvider = ({ userId, portalId, ...props }) => {
  const [socket, setSocket] = useState(null)
  const [chats, setChats] = useState([])
  const [currChatIndex, setCurrChatIndex] = useState(-1)
  const [currChatName, setCurrChatName] = useState('')
  const [onlineUsers, setOnlineUsers] = useState([])
  const [portalUsers, setPortalUsers] = useState([])
  const [portalUsersMap, setPortalUsersMap] = useState({})
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const [loadingChats, setLoadingChats] = useState(true)

  const { me } = useContext(CurrentUserContext)
  const { portal, fetchPortalUsers } = useContext(CurrentPortalContext)
  const { setSnackBarMessage } = useContext(NotificationContext)
  const [newMessage, setNewMessage] = useState(null)

  useEffect(() => {
    const fetchParticipants = async () => {
      try {
        const users = await fetchPortalUsers(portal.name)
        setPortalUsers(users.map((el) => el.user))

        const usersObj = users.reduce((acc, curr) => {
          acc[curr.user.id] = curr.user
          return acc
        }, {})

        setPortalUsersMap(usersObj)
      } catch (error) {
        console.error('Failed to fetch portal users:', error)
      }
    }

    fetchParticipants()
  }, [portalId])

  const onConnect = () => {
    console.log('connected')
    setLoadingChats(false)
  }

  const onDisconnect = (data) => {
    console.log('Disconnected:', data.connectionId)
  }

  const processChats = (chats) => {
    const processedChats = chats.map((chat) => {
      const unreadMessages = chat.messages?.filter(
        (message) =>
          message.senderId !== me.id &&
          new Date(message.timestamp) > new Date(chat.lastSeen)
      ).length

      let lastMessageTime = null

      if (chat.messages) {
        lastMessageTime = chat.messages[chat.messages?.length - 1]?.timestamp
      }

      return {
        ...chat,
        lastMessageTime,
        unreadMessages
      }
    })

    return processedChats
  }

  const getChatName = (chat, currUserId) => {
    if (chat?.name) return chat.name
    const participant = chat?.participants.find((p) => p.userId !== currUserId)
    if (participant.userId in portalUsersMap) {
      return portalUsersMap[participant.userId]?.name
    }
  }

  useEffect(() => {
    if (!newMessage) return
    if (
      newMessage.senderId !== me.id &&
      chats[currChatIndex]?.id !== newMessage.chatId
    ) {
      setSnackBarMessage(
        `New Chat Recieved\n${getChatName(newMessage.chat, me.id)}: ${
          newMessage.text
        }`,
        'info'
      )
    }
    setNewMessage(null)
  }, [newMessage, chats, currChatIndex, me])

  const onMessageSent = ({ message }) => {
    setNewMessage(message)

    setChats((prevChats) => {
      return processChats(
        prevChats.map((chat) => {
          if (chat.id === message.chatId) {
            return {
              ...chat,
              messages: chat.messages ? [...chat.messages, message] : [message]
            }
          } else {
            return chat
          }
        })
      )
    })
  }

  const onGetUserChats = ({ chats }) => {
    setChats(processChats(chats))
  }

  const onCreateChat = ({ chatWithUsers }) => {
    setChats((prev) => [...prev, chatWithUsers])
  }

  const onGetOnlineChatUsers = ({ users }) => {
    setOnlineUsers(users)
  }

  const onGetOnlinePortalUsers = ({ users }) => {
    setOnlineUsers(users.map((user) => user.userId))
  }

  const onUserConnected = ({ userId }) => {
    if (!onlineUsers.includes(userId)) {
      setOnlineUsers((prev) => [...prev, parseInt(userId)])
    }
  }

  const onUserDisconnected = ({ userId }) => {
    setOnlineUsers((prev) => prev.filter((id) => id !== parseInt(userId)))
  }

  const onUpdateLastSeen = ({ chatId, userId, lastSeen }) => {
    setChats((prevChats) => {
      return processChats(
        prevChats.map((chat) => {
          if (chat.id === chatId) {
            if (userId === me.id) {
              return {
                ...chat,
                lastSeen
              }
            } else {
              return {
                ...chat,
                otherUserLastSeen: lastSeen
              }
            }
          }
          return chat
        })
      )
    })
  }

  const onIsTyping = ({ chatId, senderId }) => {
    if (senderId !== me.id) {
      setChats((prevChats) => {
        return processChats(
          prevChats.map((chat) => {
            if (chat.id === chatId) {
              return {
                ...chat,
                isTypingDate: new Date()
              }
            }
            return chat
          })
        )
      })
    }
  }

  useEffect(async () => {
    if (!userId || !portalId) return

    const socket = io.connect(process.env.REACT_APP_CHATVU_BACKEND, {
      auth: {
        token: await getJWT()
      },
      query: {
        userId,
        portalId,
        userName: me.name,
        userEmail: me.email,
        userNumber: me.number,
        applicationId: 'televu-isee'
      }
    })

    setSocket(socket)

    socket.on('connect_error', (error) => {
      console.error('Connection error:', error)
    })
    socket.on('connect', onConnect)
    socket.on('disconnect', onDisconnect)
    socket.on('chat:getUserChats', onGetUserChats)
    socket.on('chat:sendMessage', onMessageSent)
    socket.on('user:getOnlineChatUsers', onGetOnlineChatUsers)
    socket.on('chat:updateLastSeen', onUpdateLastSeen)
    socket.on('user:connected', onUserConnected)
    socket.on('user:disconnected', onUserDisconnected)
    socket.on('user:getOnlinePortalUsers', onGetOnlinePortalUsers)
    socket.on('chat:chatCreated', onCreateChat)
    socket.on('chat:isTyping', onIsTyping)
    socket.onAny((eventName, ...args) => {
      // console.log('any', eventName, args)
    })

    return () => {
      if (socket) {
        socket.off('chat:getUserChats')
        socket.off('chat:sendMessage')
        socket.off('user:getOnlineChatUsers')
        socket.off('chat:updateLastSeen')
        socket.off('user:connected')
        socket.off('user:disconnected')
        socket.off('user:getOnlinePortalUsers')
        socket.off('chat:chatCreated')
        socket.off('chat:isTyping')
        socket.disconnect()
      }
    }
  }, [userId, portalId])

  return (
    <ChatSocketContext.Provider
      value={{
        socket,
        chats,
        currChatIndex,
        setCurrChatIndex,
        currChatName,
        setCurrChatName,
        onlineUsers,
        setOnlineUsers,
        portalUsers,
        setPortalUsers,
        portalUsersMap,
        setPortalUsersMap,
        isSettingsOpen,
        setIsSettingsOpen,
        loadingChats,
        setLoadingChats
      }}
    >
      {props.children}
    </ChatSocketContext.Provider>
  )
}
