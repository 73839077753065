/* eslint-disable no-unused-vars */
import React from 'react'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'
import { Auth } from 'aws-amplify'
import { containsEmail } from '../../../utils/functions'
import { PasswordInput } from '../PasswordInput/PasswordInput'
import { PasswordCheck } from '../PasswordCheck/PasswordCheck'
import { useTranslation } from 'react-i18next'
import { BrandedButton } from '../../Shared/BrandedButton/BrandedButton.component'

export const ResetPasswordForm = ({ prefilledEmail }) => {
  const { t, i18n } = useTranslation()
  const [showCode, setShowCode] = React.useState(false)
  const [email, setEmail] = React.useState(prefilledEmail)
  const [code, setCode] = React.useState('')
  const [newPassword, setNewPassword] = React.useState('')
  const [emailErrorText, setEmailErrorText] = React.useState('')
  const [passwordErrorText, setPasswordErrorText] = React.useState('')

  const forgotPassword = () => {
    Auth.forgotPassword(email)
      .then((data) => setShowCode(true))
      .catch((err) => {
        setEmailErrorText(err.message)
      })
  }

  const signIn = async () => {
    try {
      const user = await Auth.signIn(prefilledEmail, newPassword)
    } catch (err) {
      setPasswordErrorText(err.message)
    }
  }

  const forgotPasswordSubmit = () => {
    Auth.forgotPasswordSubmit(prefilledEmail, code, newPassword)
      .then((data) => {
        setPasswordErrorText('')
        signIn()
      })
      .catch((err) => {
        setPasswordErrorText(err.message)
      })
  }

  React.useEffect(() => {
    if (email.length > 0) {
      if (!containsEmail(email)) {
        setEmailErrorText('Please enter a valid email address')
      } else {
        setEmailErrorText('')
      }
    }
  }, [email])

  return showCode
    ? (
    <div className="loginForm">
      <TextField
        id="outlined-name"
        label={t('Verification Code')}
        className="textInput"
        value={code}
        onChange={(event) => setCode(event.target.value)}
        sx={{ marginBottom: 2 }}
      />
      <PasswordInput
        value={newPassword}
        onChange={(event) => setNewPassword(event.target.value)}
        error={passwordErrorText.length > 0}
        helperText={passwordErrorText}
        label={t('New Password')}
        sx={{ marginBottom: 2 }}
      />
      <PasswordCheck password={newPassword} />
      <BrandedButton
        onClick={forgotPasswordSubmit}
        disabled={code.length === 0 || newPassword.length === 0}
        buttonLabel={'Submit'}
      />
    </div>
      )
    : (
    <div className="loginForm">
      <TextField
        id="outlined-name"
        label={t('Email')}
        className="textInput"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        sx={{ marginBottom: 2 }}
        error={emailErrorText.length > 0}
        helperText={emailErrorText}
      />
      <BrandedButton
        onClick={forgotPassword}
        disabled={emailErrorText.length > 0 || email.length === 0}
        buttonLabel={'Submit'}
      />
    </div>
      )
}

ResetPasswordForm.propTypes = {
  prefilledEmail: PropTypes.string
}
