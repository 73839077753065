import React, { useContext, memo, useEffect } from 'react'
import List from '@mui/material/List'
import WebIcon from '@mui/icons-material/Web'
import { FaGlasses } from 'react-icons/fa'
import { FiUsers, FiCamera, FiSettings } from 'react-icons/fi'
import {
  MdOutlineConnectedTv,
  MdCloudQueue,
  MdLogout,
  MdOutlineAnalytics,
  MdOutlineGroup
} from 'react-icons/md'
import { RiFlowChart, RiDashboardLine } from 'react-icons/ri'
import { HiOutlineChat } from 'react-icons/hi'
import SideBarRow from './SideBarRow.component'
import { CurrentPortalContext } from '../../../context/Shared/CurrentPortal.context'
import { CurrentUserContext } from '../../../context/Shared/CurrentUser.context'
import { ChatSocketContext } from '../../../context/ChatSocket/ChatSocket.context'
import PropTypes from 'prop-types'
import { theme } from '../../../styles/theme.style'
import { routify } from '../../../utils/functions'
import { Typography } from '@mui/material'
import styles from './SideBar.module.scss'

export const SideBarMenu = ({ signOut, navigateAway }) => {
  const { portal } = useContext(CurrentPortalContext)
  const { me } = useContext(CurrentUserContext)
  const { chats } = useContext(ChatSocketContext)
  const [unreadMessagesCount, setUnreadMessagesCount] = React.useState(0)

  useEffect(() => {
    let unreadMessagesCount = 0
    chats.forEach((chat) => (unreadMessagesCount += chat.unreadMessages))
    setUnreadMessagesCount(unreadMessagesCount)
  }, [chats])

  return (
    <List className="toolBarItems">
      <SideBarRow
        pageName={theme.HOMEVU}
        icon={<RiDashboardLine />}
        clickAction={() => navigateAway('/' + routify(theme.HOMEVU))}
      />
      <SideBarRow
        pageName={theme.GLASSVU}
        icon={<FaGlasses />}
        clickAction={() => navigateAway('/' + routify(theme.GLASSVU))}
      />
      <SideBarRow
        pageName={theme.REMOTEVU}
        icon={<FiUsers />}
        clickAction={() => navigateAway('/' + routify(theme.REMOTEVU))}
      />
      <SideBarRow
        pageName={theme.SCREENVU}
        icon={<MdOutlineConnectedTv />}
        clickAction={() => navigateAway('/' + routify(theme.SCREENVU))}
      />
      <SideBarRow
        pageName={theme.CAMVU}
        icon={<FiCamera />}
        clickAction={() => navigateAway('/' + routify(theme.CAMVU))}
      />
      <SideBarRow
        pageName={theme.GROUPVU}
        icon={<MdOutlineGroup />}
        clickAction={() => navigateAway('/' + routify(theme.GROUPVU))}
      />
      {portal?.hasFlowVULicence && (
        <SideBarRow
          pageName={theme.FLOWVU}
          icon={<RiFlowChart />}
          clickAction={() => navigateAway('/' + routify(theme.FLOWVU))}
        />
      )}
      {(['User', 'Super User', 'Manager'].includes(portal?.role) ||
        me.admin) && (
        <SideBarRow
          pageName={theme.FILEVU}
          icon={<MdCloudQueue />}
          clickAction={() => navigateAway('/' + routify(theme.FILEVU))}
        />
      )}
      {/* {(['User', 'Super User', 'Manager'].includes(portal?.role) ||
        me.admin) && (
        <SideBarRow
          pageName={theme.VIDEOVU}
          icon={<MdCloudQueue />}
          clickAction={() => navigateAway('/' + routify(theme.VIDEOVU))}
        />
      )} */}
      {(['Super User', 'Manager'].includes(portal?.role) || me.admin) && (
        <SideBarRow
          pageName={theme.REPORTVU}
          icon={<MdOutlineAnalytics />}
          clickAction={() => navigateAway('/' + routify(theme.REPORTVU))}
        />
      )}
      <SideBarRow
        pageName={theme.CHATVU}
        icon={
          <>
            <HiOutlineChat />
            {unreadMessagesCount > 0
              ? (
              <Typography className={styles.unreadMessagesCount}>
                {unreadMessagesCount}
              </Typography>
                )
              : null}
          </>
        }
        clickAction={() => navigateAway('/' + routify(theme.CHATVU))}
      />
      {me?.admin && (
        <SideBarRow
          pageName={theme.PORTALVU}
          icon={<WebIcon />}
          clickAction={() => navigateAway('/' + routify(theme.PORTALVU))}
        />
      )}
      <SideBarRow
        pageName={theme.SETTINGS}
        icon={<FiSettings />}
        clickAction={() => navigateAway('/' + routify(theme.SETTINGS))}
      />
      <SideBarRow
        pageName={'Logout'}
        icon={<MdLogout />}
        clickAction={signOut}
        textColor={theme.COLOR_GOLD}
      />
    </List>
  )
}

SideBarMenu.propTypes = {
  navigateAway: PropTypes.func,
  signOut: PropTypes.func
}

export const MemoizedSideBarMenu = memo(SideBarMenu)
