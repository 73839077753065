import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import AppRouter from './routes/AppRouter'
import { ThemeProvider } from '@mui/styles'
import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import { BrowserRouter as Router } from 'react-router-dom'
import Auth from './pages/Auth/Auth.page'
import { CurrentUserContextProvider } from './context/Shared/CurrentUser.context'
import { CurrentPortalContextProvider } from './context/Shared/CurrentPortal.context'
import { Amplify, Hub } from 'aws-amplify'
import awsconfig from './aws-exports'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { NotificationContext } from './context/Shared/Notification.context'
import NotificationPrompt from './components/Shared/NotificationPrompt/NotificationPrompt.component'

import './App.css'

Amplify.configure(awsconfig)

const App = () => {
  const { i18n } = useTranslation()
  document.body.dir = i18n.dir()

  const [user, setUser] = useState(null)
  const { authStatus } = useAuthenticator((context) => [context.authStatus])
  const { snackBarOpen, handleCloseSnackBar, snackBarMessage, snackBarType } =
    useContext(NotificationContext)

  useEffect(() => {
    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signIn':
          setUser(data.payload.data)
          break
        case 'signOut':
          setUser(null)
          break
      }
    })
    return () => {
      Hub.remove('auth')
    }
  }, [])

  let theme = createTheme()
  theme = responsiveFontSizes(theme)
  return (
    <>
      <NotificationPrompt
        snackBarOpen={snackBarOpen}
        handleCloseSnackBar={handleCloseSnackBar}
        snackBarMessage={snackBarMessage}
        snackBarType={snackBarType}
      />
      {user || authStatus === 'authenticated'
        ? (
        <ThemeProvider theme={theme}>
          <CurrentUserContextProvider>
            <CurrentPortalContextProvider>
              <Router>
                <AppRouter />
              </Router>
            </CurrentPortalContextProvider>
          </CurrentUserContextProvider>
        </ThemeProvider>
          )
        : (
        <>
          <Auth />
        </>
          )}
    </>
  )
}

export default App
