import React, { useState, createContext } from 'react'
import VideoVUService from '../../services/VideoVU/VideoVU.service'
import PropTypes from 'prop-types'
// import { trackEvent } from '../../utils/functions'

export const VideoVUContext = createContext()

export const VideoVUContextProvider = (props) => {
  const [allVideos, setAllVideos] = useState({
    privateVideos: [],
    publicVideos: []
  })
  const [allTags, setAllTags] = useState([])
  const [getAllVideosLoading, setGetAllVideosLoading] = useState(false)

  const getAllVideos = async ({ page = 1, limit, searchBy, searchTerm }) => {
    try {
      setGetAllVideosLoading(true)
      const res = await VideoVUService.getVideos(
        page,
        limit,
        searchBy,
        searchTerm
      )
      setAllVideos(res.data)
    } catch (error) {
      console.log(error)
    }
    setGetAllVideosLoading(false)
  }

  const getTags = async (portalName) => {
    try {
      const res = await VideoVUService.getTags(portalName)
      setAllTags(res.data)
    } catch (error) {
      console.log(error)
    }
  }

  const createTags = async (portalName, tags) => {
    try {
      await VideoVUService.createTag(portalName, tags)
      await getTags(portalName)
    } catch (error) {
      console.log(error)
    }
  }

  const removeTag = async (portalName, tagId) => {
    try {
      await VideoVUService.removeTag(portalName, tagId)
      await getTags(portalName)
    } catch (error) {
      console.log(error)
    }
  }

  const updateVideoMetadata = async (fileName, videoMetadata) => {
    return await VideoVUService.updateVideoMetadata(fileName, videoMetadata)
  }

  const addVideoTag = async (videoId, tagId) => {
    return await VideoVUService.addVideoTag(videoId, tagId)
  }

  const deleteVideoTag = async (videoId, tagId) => {
    return await VideoVUService.deleteVideoTag(videoId, tagId)
  }

  return (
    <VideoVUContext.Provider
      value={{
        allVideos,
        getAllVideos,
        createTags,
        getTags,
        allTags,
        getAllVideosLoading,
        removeTag,
        updateVideoMetadata,
        addVideoTag,
        deleteVideoTag
      }}
    >
      {props.children}
    </VideoVUContext.Provider>
  )
}

VideoVUContextProvider.propTypes = {
  children: PropTypes.node.isRequired
}
