import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import './NotificationPrompt.scss'
import PropTypes from 'prop-types'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'

const NotificiationPrompt = ({
  snackBarOpen,
  handleCloseSnackBar,
  snackBarMessage,
  snackBarType
}) => {
  return (
    <Snackbar
      open={snackBarOpen}
      autoHideDuration={6000}
      onClose={handleCloseSnackBar}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <div className={`notificationPrompt notificationPrompt__${snackBarType}`}>
        <div className="notificationPrompt__content">
          <div className="notificationPrompt__content__text">
            {snackBarMessage}
          </div>
          <IconButton onClick={handleCloseSnackBar}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    </Snackbar>
  )
}

NotificiationPrompt.propTypes = {
  snackBarOpen: PropTypes.bool,
  handleCloseSnackBar: PropTypes.func,
  snackBarMessage: PropTypes.string,
  snackBarType: PropTypes.string
}

export default React.memo(NotificiationPrompt)
