import { Auth } from 'aws-amplify'
import moment from 'moment'
import { pathNameMap } from './constants'
import { theme } from '../styles/theme.style'

export const getModalStyle = () => {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  }
}

export const getJWT = async () => {
  return Auth.currentSession().then((res) => {
    const getIdToken = res.getIdToken()
    const jwt = getIdToken.getJwtToken()
    return jwt
  })
}

export const getSummaryAnalyticsGraph = (summaryAnalytics) => {
  const labels = []
  const data = []
  summaryAnalytics.forEach((item) => {
    labels.push(moment(item.final_date, 'YYYY-MM-DD').format('MMM YYYY'))
    data.push(item.count)
  })

  const finalData = {
    labels,
    datasets: [
      {
        label: 'Calls',
        data,
        borderColor: theme.COLOR_LIGHT_BLUE,
        backgroundColor: theme.COLOR_DARK_TEAL
      }
    ]
  }
  return finalData
}

export const formatAnalytics = (allAnalytics) => {
  const data = []
  allAnalytics?.forEach((analytics) => {
    if (analytics.participantEvents && analytics.sessionEvents.length > 0) {
      let users = analytics.participantEvents.map((user) => user.clientData)
      users = [...new Set(users)]

      // Initialize variables for min start time and max end time
      let minStartTime = new Date(analytics.sessionEvents[0].startTime)
      let maxEndTime = new Date(analytics.sessionEvents[0].timestamp)
      let totalDuration = 0

      // Loop through sessionEvents to find min start time, max end time and total duration
      analytics.sessionEvents.forEach((event) => {
        const startTime = new Date(event.startTime)
        const endTime = new Date(event.timestamp)

        if (startTime < minStartTime) {
          minStartTime = startTime
        }

        if (endTime > maxEndTime) {
          maxEndTime = endTime
        }

        totalDuration += endTime - startTime
      })

      data.push({
        id: analytics.sessionId,
        user: users.join(', '),
        startTimeString: minStartTime.toString().substring(0, 25), // substring cuts out timezone
        endTimeString: maxEndTime.toString().substring(0, 25), // substring cuts out timezone
        duration: convertMSToDuration(totalDuration),
        portal: analytics.portal.name,
        recordings: analytics.sessionFiles?.map((recording) => recording.name),
        topic: analytics?.scheduleMeeting?.topic
      })
    }
  })
  return data
}

const convertMSToDuration = (ms) => {
  // Converts a time diff in ms to human output like 00:00:00
  let h, m, s
  h = Math.floor(ms / 1000 / 60 / 60)
  m = Math.floor((ms / 1000 / 60 / 60 - h) * 60)
  s = Math.floor(((ms / 1000 / 60 / 60 - h) * 60 - m) * 60)
  s < 10 ? (s = `0${s}`) : (s = `${s}`)
  m < 10 ? (m = `0${m}`) : (m = `${m}`)
  h < 10 ? (h = `0${h}`) : (h = `${h}`)
  return `${h}:${m}:${s}`
}

export const createDevices = (allDevices) => {
  const allDevicesArray = []
  for (const key in allDevices) {
    allDevicesArray.push(allDevices[key])
  }
  return allDevicesArray
}

export const checkCurrent = (ref) => {
  if (ref) {
    if (ref.current) {
      return true
    } else {
      return false
    }
  }
  return false
}

export const shouldHaveAddModal = (location) => {
  return [
    theme.GLASSVU,
    theme.REMOTEVU,
    theme.SCREENVU,
    theme.CAMVU,
    theme.GROUPVU,
    theme.FLOWVU,
    theme.PORTALVU
  ].includes(pathNameMap[location.pathname])
}

export const trackEvent = (event, data) => {
  const analytics = window.analytics
  if (analytics) {
    analytics.track(event, data)
  }
}

export const initiateAnalytics = (identity, data) => {
  const analytics = window.analytics
  if (analytics) {
    analytics.identify(identity, data)
  }
}

export const getDefaultMicrophoneState = (me) => {
  if (me.userType === 'ScreenVU') {
    return false
  } else {
    return true
  }
}

export const getDefaultVideoState = (me) => {
  if (me.userType === 'ScreenVU') {
    return true
  } else {
    return false
  }
}

export const getWebcam = (me, webcams) => {
  let value = ''
  if (me.userType === 'ScreenVU') {
    Object.keys(webcams).forEach((webcam) => {
      const name = webcams[webcam]
      if (name.includes('OBS Virtual Camera')) {
        value = webcam
      }
    })
  }
  return value
}

export const isScreenVU = (me) => {
  return me.userType === 'ScreenVU'
}

export const parseSubscriber = (subscriber) => {
  const userMetadata = JSON.parse(subscriber.stream?.connection?.data)
  const id = subscriber.stream?.streamId
  const type = userMetadata.userType
  const name = userMetadata.clientData
  const email = userMetadata.email
  return { id, type, name, email }
}

export const containsEmail = (text) => {
  return /\S+@\S+\.\S+/.test(text)
}

export const containsUpperCase = (text) => {
  return /[A-Z]/.test(text)
}

export const containsLowercase = (text) => {
  return /[a-z]/.test(text)
}

export const containsNumber = (text) => {
  return /[0-9]/.test(text)
}

export const containsSpecialCharachter = (text) => {
  return /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~`]/.test(text)
}

export const getInitials = (name) => {
  if (!name) {
    return '?'
  }
  const spaced = name.split(' ')
  const initials = spaced.map((name) => name.charAt(0))
  return initials.join('')
}

export const getFirstName = (name) => {
  if (!name) {
    return 'User'
  }
  const spaced = name.split(' ')
  return spaced[0]
}

export const getSortedPortals = (portals) => {
  const sortedPortals = portals.sort((a, b) => {
    return a.Portal.name.toLowerCase().localeCompare(b.Portal.name)
  })
  return sortedPortals
}

export const getCsvfromJsonObject = (jsonObject) => {
  const replacer = (key, value) => (value === null ? '' : value) // specify how you want to handle null values here
  const header = Object.keys(jsonObject[0])
  const csv = [
    header.join(','), // header row first
    ...jsonObject.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    )
  ].join('\r\n')
  return csv
}

export const routify = (str) => str.toLowerCase().replace(' ', '-')

export const formatFileSize = (size) => {
  if (Math.trunc(size / 1000000) !== 0) {
    return `${Math.trunc(size / 1000000)} MB`
  } else {
    return `${Math.trunc(size / 1000)} KB`
  }
}

export const stringAvatar = (name) => {
  return {
    children: `${name?.split(' ')[0][0]}${
      name?.split(' ').length > 1 ? name?.split(' ')[1][0] : ''
    }`
  }
}

export const formatLogicNodes = (logicNodes, flowvuId) => {
  const formattedLogicNodes = []
  for (const node of logicNodes) {
    let totalTimeInSeconds = 0
    if (node.data.label.props.isTimerBlock) {
      const time = node.data.label.props.label
      const timeParts = time.split(':')
      for (const timePart of timeParts) {
        if (timePart.endsWith('hr')) {
          totalTimeInSeconds += parseInt(timePart.slice(0, -2)) * 60 * 60
        } else if (timePart.endsWith('min')) {
          totalTimeInSeconds += parseInt(timePart.slice(0, -3)) * 60
        } else if (timePart.endsWith('sec')) {
          totalTimeInSeconds += parseInt(timePart.slice(0, -3))
        }
      }
    }
    const newNode = {
      id: node.id,
      nodeType: 'logic',
      startNode: node.startNode,
      posX: Math.round(node.position.x * 100) / 100,
      posY: Math.round(node.position.y * 100) / 100,
      displayText: node.data.label.props.label,
      flowvuId
    }
    if (node.data.label.props.isTimerBlock) {
      newNode.timerBlock = totalTimeInSeconds
    }
    if (node.data.label.props.mediaBlockType) {
      newNode.mediaBlockType = node.data.label.props.mediaBlockType
    }
    formattedLogicNodes.push(newNode)
  }
  return formattedLogicNodes
}

export const formatMediaNodes = (mediaNodes, flowvuId) => {
  const formattedMediaNodes = []
  for (const node of mediaNodes) {
    const contentUrl = node.data.label.props.src.startsWith('https')
      ? getTrimmedContentUrl(node.data.label.props.src)
      : node.data.label.props.src
    const formattedPosX = node.position?.x
      ? Math.round(node.position.x * 100) / 100
      : null
    const formattedPosY = node.position?.y
      ? Math.round(node.position.y * 100) / 100
      : null

    formattedMediaNodes.push({
      id: node.id,
      nodeType: node.data.label.type === 'video' ? 'video' : 'slide',
      startNode: false,
      contentUrl,
      posX: formattedPosX,
      posY: formattedPosY,
      flowvuId
    })
  }
  return formattedMediaNodes
}

function getTrimmedContentUrl (fullUrl) {
  const prefixEnding = fullUrl.indexOf('flowVU/')
  const extensions = ['.png', '.mp4', '.mkv']
  const suffixBeginning = extensions.find((extension) =>
    fullUrl.includes(extension)
  )
  return fullUrl.slice(
    prefixEnding,
    fullUrl.indexOf(suffixBeginning) + suffixBeginning.length
  )
}

export const formatEdges = (edges, flowvuId) => {
  const formattedEdges = []
  for (const edge of edges) {
    formattedEdges.push({
      id: edge.id,
      source: edge.source,
      target: edge.target,
      flowvuId
    })
  }
  return formattedEdges
}
