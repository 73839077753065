import React, { useContext, useCallback, memo } from 'react'
import Drawer from '@mui/material/Drawer'
import { useNavigate } from 'react-router-dom'
import { Footnote } from './Footnote.component'
import { SideBarMenu } from './SideBarMenu.component'
import TopSideBar from './TopSideBar.component'
import { SideBarContext } from '../../../context/Shared/SideBar.context'
import PropTypes from 'prop-types'
import styles from './SideBar.module.scss'

const SideBar = ({ onlineStatus, signOut }) => {
  const navigate = useNavigate()
  const { isMobile, setSideBarVisibility, sideBarVisibility } =
    useContext(SideBarContext)

  const navigateAway = useCallback((location) => {
    navigate(location)
    if (isMobile) {
      setSideBarVisibility(false)
    }
  }, [])

  const isRTL = document.body.dir === 'rtl'

  return (
    sideBarVisibility && (
      <Drawer
        className={styles.drawer}
        variant="permanent"
        dir={document.body.dir}
        anchor={isRTL ? 'right' : 'left'}
      >
        <div
          className={
            isRTL ? styles.drawerContainerRight : styles.drawerContainerLeft
          }
        >
          <TopSideBar onlineStatus={onlineStatus} />
          <SideBarMenu signOut={signOut} navigateAway={navigateAway} />
          <Footnote />
        </div>
      </Drawer>
    )
  )
}

SideBar.propTypes = {
  onlineStatus: PropTypes.bool,
  signOut: PropTypes.func
}

export default memo(SideBar)
