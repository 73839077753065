// TODO: generate inidvidual SCSS file for better maintainability

import React, { memo } from 'react'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useTranslation } from 'react-i18next'
import { pathNameMap, namePathMap } from '../../../utils/constants'
import { useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import styles from './SideBar.module.scss'
import { theme } from '../../../styles/theme.style'

const SideBarRow = ({ pageName, icon, textColor, clickAction }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <ListItem
      button
      key={pageName}
      onClick={clickAction || (() => navigate(namePathMap[pageName]))}
      className={
        pathNameMap[location.pathname] === pageName
          ? styles.sideBarRowActive
          : styles.sideBarRow
      }
    >
      <ListItemIcon
        sx={
          textColor
            ? { fontSize: 20, color: textColor }
            : { fontSize: 20, color: theme.COLOR_WHITE }
        }
        className={
          pathNameMap[location.pathname] === pageName
            ? styles.active
            : styles.menuRow
        }
      >
        {icon}
      </ListItemIcon>
      <ListItemText
        className={
          pathNameMap[location.pathname] === pageName
            ? styles.active
            : styles.menuRow
        }
        primary={t(pageName)}
        sx={textColor ? { color: textColor } : { color: theme.COLOR_WHITE }}
      />
    </ListItem>
  )
}

SideBarRow.propTypes = {
  pageName: PropTypes.string,
  icon: PropTypes.node,
  textColor: PropTypes.string,
  clickAction: PropTypes.func
}

export default memo(SideBarRow)
