import React, { createContext, useState, useEffect } from 'react'
import FlowVUService from '../../services/FlowVU/FlowVU.service'
import PropTypes from 'prop-types'
import { useNavigate, useLocation } from 'react-router-dom'
import LogicNode from '../../components/FlowVU/LogicNode/LogicNode'
import VideoSlide from '../../components/FlowVU/CreateFlow/VideoSlide/VideoSlide.component'
export const FlowVUContext = createContext()

export const FlowVUContextProvider = (props) => {
  const [flowvus, setFlowvus] = useState([])
  const [sidebarContent, setSidebarContent] = useState([])
  const [initialNodes, setInitialNodes] = useState([])
  const [initialEdges, setInitialEdges] = useState([])
  const [flowvuName, setFlowvuName] = useState('')
  const [showEditModalTitle, setShowEditModalTitle] = useState('')
  const [showEditLogicModal, setShowEditLogicModal] = useState(false)
  const [showEditTimerModal, setShowEditTimerModal] = useState(false)
  const [showAddTimerModal, setShowAddTimerModal] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    setFlowvus([])
  }, [location.pathname])

  const createFlowvu = async (slides) => {
    const response = await FlowVUService.createFlowvu(slides)
    return response
  }

  const appendSlidesToExistingFlow = async (slides) => {
    const response = await FlowVUService.appendSlidesToExistingFlow(slides)
    return response
  }

  const appendVideoToExistingFlow = async (video) => {
    const response = await FlowVUService.appendVideoToExistingFlow(video)
    return response
  }
  const getFlowvuOperation = async (flowvuOperationId) => {
    const flowvuStatus = await FlowVUService.getFlowvuOperation(
      flowvuOperationId
    )
    return flowvuStatus
  }

  const setFlowvuDashboardData = async (portalId) => {
    const flowvus = await FlowVUService.listFlowvus(portalId)
    setFlowvus(flowvus.data)
  }

  const updateFlowvuStatus = async (flowvuId, status, portalId) => {
    await FlowVUService.updateFlowvuStatus(flowvuId, status)
    await setFlowvuDashboardData(portalId)
  }

  const saveFlowvu = async (flowvuId, nodes, sidebarContent, edges) => {
    await FlowVUService.saveFlowvu(flowvuId, nodes, sidebarContent, edges)
  }

  const deleteFlowvu = async (flowvuId, portalId) => {
    await FlowVUService.deleteFlowvu(flowvuId)
    await setFlowvuDashboardData(portalId)
  }

  const setFlowvuData = async (flowId) => {
    const flowvu = await FlowVUService.getFlowvu(flowId)
    const nodesInFlowvu = flowvu.data.flowvuNode.filter(
      (node) => node.posX || node.posY
    )
    setInitialNodes(getInitialNodes(nodesInFlowvu))
    setInitialEdges(flowvu.data.flowvuConnector)
    setSidebarContent(getSidebarContent(flowvu.data.flowvuNode))
    setFlowvuName(flowvu.data.name)
  }

  const getInitialNodes = (nodes) => {
    const slideNodes = getInitialSlideNodes(
      nodes.filter((node) => node.nodeType === 'slide')
    )
    const logicNodes = getInitialLogicNodes(
      nodes.filter((node) => node.nodeType === 'logic')
    )
    const videoNodes = getInitialVideoNodes(
      nodes.filter((node) => node.nodeType === 'video')
    )
    return slideNodes.concat(logicNodes).concat(videoNodes)
  }

  const getInitialSlideNodes = (slideNodes) => {
    return slideNodes.map((node) => {
      return {
        id: node.id,
        type: 'default',
        position: {
          x: node.posX,
          y: node.posY
        },
        data: {
          label: (
            <img src={node.contentUrl} datakey={node} alt="draggable image" />
          )
        }
      }
    })
  }

  const getInitialVideoNodes = (videoNodes) => {
    return videoNodes.map((node) => {
      return {
        id: node.id,
        type: 'default',
        position: {
          x: node.posX,
          y: node.posY
        },
        data: {
          label: <VideoSlide contentUrl={node.contentUrl} datakey={node} />
        }
      }
    })
  }

  const getInitialLogicNodes = (logicNodes) => {
    return logicNodes.map((node) => {
      return {
        id: node.id,
        type: 'default',
        position: {
          x: node.posX,
          y: node.posY
        },
        data: {
          label: (
            <LogicNode
              label={node.displayText}
              onClickHandler={() => {
                if (node.timerBlock) {
                  setShowEditTimerModal(true)
                } else {
                  setShowEditLogicModal(true)
                  if (node.mediaBlockType === 'video') {
                    setShowEditModalTitle('Edit Video Node')
                  } else if (node.mediaBlockType === 'photo') {
                    setShowEditModalTitle('Edit Photo Node')
                  } else {
                    setShowEditModalTitle('Edit Logic Node')
                  }
                }
              }}
              isTimerBlock={!!node.timerBlock}
              mediaBlockType={node.mediaBlockType}
            />
          )
        },
        startNode: node.startNode
      }
    })
  }

  const getSidebarContent = (nodes) => {
    const contentNodes = nodes.filter((node) => !node.id.startsWith('logic'))
    let label
    return contentNodes.map((node, index) => {
      if (node.nodeType === 'video') {
        label = (
          <video
            src={node.contentUrl}
            datakey={node}
            alt="draggable video file"
          />
        )
      } else {
        label = (
          <img src={node.contentUrl} datakey={node} alt="draggable image" />
        )
      }

      return {
        id: node.id,
        type: node.nodeType,
        hidden: isInViewport(node),
        position: { x: node.posX, y: node.posY },
        data: { label }
      }
    })
  }

  const isInViewport = (node) => {
    return node.posX !== null || node.posY !== null
  }

  const goToCreateNewWorkFlow = () => {
    navigate('/flowvu/create')
  }

  return (
    <FlowVUContext.Provider
      value={{
        sidebarContent,
        setSidebarContent,
        getSidebarContent,
        initialNodes,
        initialEdges,
        flowvus,
        flowvuName,
        setFlowvuDashboardData,
        updateFlowvuStatus,
        deleteFlowvu,
        setFlowvuData,
        goToCreateNewWorkFlow,
        showEditLogicModal,
        setShowEditLogicModal,
        createFlowvu,
        appendSlidesToExistingFlow,
        saveFlowvu,
        getFlowvuOperation,
        appendVideoToExistingFlow,
        showEditTimerModal,
        setShowEditTimerModal,
        showAddTimerModal,
        setShowAddTimerModal,
        showEditModalTitle,
        setShowEditModalTitle
      }}
    >
      {props.children}
    </FlowVUContext.Provider>
  )
}

FlowVUContextProvider.propTypes = {
  children: PropTypes.object
}
