import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './i18n'
import App from './App'
import reportWebVitals from './reportWebVitals'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Authenticator } from '@aws-amplify/ui-react'
import { NotificationContextProvider } from './context/Shared/Notification.context'

ReactDOM.render(
  <React.StrictMode>
    <Authenticator.Provider>
      <NotificationContextProvider>
        <App />
      </NotificationContextProvider>
    </Authenticator.Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
