import React, { useContext } from 'react'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { CurrentPortalContext } from '../../../context/Shared/CurrentPortal.context'
import { CurrentUserContext } from '../../../context/Shared/CurrentUser.context'
import './SelectPortal.style.scss'

export const SelectPortal = () => {
  const { currentPortal, handlePortalChange } = useContext(CurrentPortalContext)
  const { me, portals } = useContext(CurrentUserContext)
  return (
    <div className="portalDropDown">
      {me && (
        <Select
          value={currentPortal}
          onChange={(e) => handlePortalChange(e.target.value)}
          sx={{
            width: 220,
            height: 40,
            backgroundColor: 'white',
            borderRadius: 10
          }}
        >
          {portals.map((portal, i) => {
            return (
              <MenuItem style={{ fontSize: 14 }} key={i} value={portal.id}>
                {portal.name}
              </MenuItem>
            )
          })}
        </Select>
      )}
    </div>
  )
}
