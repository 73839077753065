import { iseeInstance } from '../axiosService'

class VideoVUService {
  async getVideos (page, limit = 25, searchBy = '', searchTerm = '') {
    return iseeInstance.get(
      `/files/videos?page=${page}&limit=${limit}&searchBy=${searchBy}&searchTerm=${searchTerm}`
    )
  }

  async getTags (portalName) {
    return iseeInstance.get(`portals/${portalName}/tags`)
  }

  async createTag (portalName, tags) {
    return iseeInstance.post(`portals/${portalName}/tags`, { tags })
  }

  async removeTag (portalName, tagId) {
    return iseeInstance.delete(`portals/${portalName}/tags/${tagId}`)
  }

  async updateVideoMetadata (videoId, metadata) {
    return iseeInstance.post(`files/metadata/${videoId}`, metadata)
  }

  async addVideoTag (videoId, tagId) {
    return iseeInstance.post(`files/metadata/${videoId}/tag`, { tagId })
  }

  async deleteVideoTag (videoId, tagId) {
    return iseeInstance.delete(`files/metadata/${videoId}/tag`, {
      data: { tagId }
    })
  }

  async createThumbnail (portalName, videoId, data, isFileUpload = false) {
    let headers = {}
    if (isFileUpload) {
      headers = {
        'Content-Type': 'multipart/form-data'
      }
    }
    return iseeInstance.post(
      `files/portal/${portalName}/metadata/${videoId}/thumbnail`,
      data,
      { headers }
    )
  }

  async mergeVideos (portalName, data) {
    return iseeInstance.post(`files/portal/${portalName}/merge`, data)
  }
}

export default new VideoVUService()
