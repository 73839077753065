import React from 'react'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import IconButton from '@mui/material/IconButton'
import './PasswordInput.scss'
import PropTypes from 'prop-types'

export const PasswordInput = ({
  value,
  onChange,
  onKeyPress,
  error,
  helperText,
  label,
  sx
}) => {
  const [showPassword, setShowPassword] = React.useState(false)
  const handleClickShowPassword = () => setShowPassword((show) => !show)

  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      value={value}
      onChange={onChange}
      onKeyPress={onKeyPress}
      error={error}
      helperText={helperText}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )
      }}
      label={label}
      sx={sx}
    />
  )
}

PasswordInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  sx: PropTypes.object
}
