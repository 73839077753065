import React from 'react'
import {
  containsUpperCase,
  containsLowercase,
  containsNumber,
  containsSpecialCharachter
} from '../../../utils/functions'
import Checkbox from '@mui/material/Checkbox'
import PropTypes from 'prop-types'
import './PasswordCheck.scss'
import { useTranslation } from 'react-i18next'

export const PasswordCheck = ({ password }) => {
  const { t } = useTranslation()
  const [isUpperCase, setIsUpperCase] = React.useState(false)
  const [isLowerCase, setIsLowerCase] = React.useState(false)
  const [isNumberCase, setIsNumberCase] = React.useState(false)
  const [isSpecialCase, setIsSpecialCase] = React.useState(false)

  React.useEffect(() => {
    setIsUpperCase(containsUpperCase(password))
    setIsLowerCase(containsLowercase(password))
    setIsNumberCase(containsNumber(password))
    setIsSpecialCase(containsSpecialCharachter(password))
  }, [password])

  return (
    <div className="requirements">
      <div className="requirement">
        <Checkbox disabled checked={isUpperCase} />
        <p>{t('Contains at least 1 upper case character')}</p>
      </div>
      <div className="requirement">
        <Checkbox disabled checked={isLowerCase} />
        <p>{t('Contains at least 1 lower case character')}</p>
      </div>
      <div className="requirement">
        <Checkbox disabled checked={isNumberCase} />
        <p>{t('Contains at least 1 number')}</p>
      </div>
      <div className="requirement">
        <Checkbox disabled checked={isSpecialCase} />
        <p>{t('Contains at least 1 special character')}</p>
      </div>
    </div>
  )
}

PasswordCheck.propTypes = {
  password: PropTypes.string
}
