import React from 'react'
import './LogicNode.css'
import PropTypes from 'prop-types'

const LogicNode = ({ label, onClickHandler, isTimerBlock, mediaBlockType }) => {
  return (
    <div onDoubleClick={onClickHandler} className={'logic-node'}>
      {label || 'Add Choices'}
    </div>
  )
}

LogicNode.propTypes = {
  label: PropTypes.string,
  onClickHandler: PropTypes.func,
  isTimerBlock: PropTypes.bool,
  isMediaBlock: PropTypes.bool
}

export default LogicNode
