import React from 'react'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

export const CustomSelect = ({ currentItem, handleItemChange, allItems }) => {
  const { t } = useTranslation()

  return (
    <Select
      value={currentItem}
      onChange={handleItemChange}
      sx={{
        width: 220,
        height: 40,
        backgroundColor: 'white',
        borderRadius: 10
      }}
    >
      {Object.keys(allItems).map((item, i) => {
        return (
          <MenuItem style={{ fontSize: 14 }} key={i} value={item}>
            {t(allItems[item])}
          </MenuItem>
        )
      })}
    </Select>
  )
}

CustomSelect.propTypes = {
  currentItem: PropTypes.string,
  handleItemChange: PropTypes.func,
  allItems: PropTypes.object
}
