import { flowvuInstance } from '../axiosService'
import {
  formatLogicNodes,
  formatMediaNodes,
  formatEdges
} from '../../utils/functions'

class FlowVUService {
  async listFlowvus (portalId) {
    return flowvuInstance.get(`/portals/${portalId}`)
  }

  async updateFlowvuStatus (flowvuId, status) {
    return flowvuInstance.patch(`/flowvus/${flowvuId}`, { status })
  }

  async deleteFlowvu (flowvuId) {
    return flowvuInstance.delete(`/flowvus/${flowvuId}`)
  }

  async saveFlowvu (flowvuId, nodes, sidebarContent, edges) {
    const logicNodes = nodes.filter((node) => node.id.startsWith('logic'))
    const formattedLogicNodes = formatLogicNodes(logicNodes, flowvuId)

    const formattedMediaNodes = formatMediaNodes(sidebarContent, flowvuId)
    const formattedEdges = formatEdges(edges, flowvuId)
    const result = await flowvuInstance.put(`/flowvus/${flowvuId}`, {
      logicNodes: formattedLogicNodes,
      mediaNodes: formattedMediaNodes,
      edges: formattedEdges
    })
    return result
  }

  async getFlowvuOperation (flowvuOperationId) {
    return await flowvuInstance.get(
      `/flowvus/flowvuoperations/${flowvuOperationId}`
    )
  }

  async createFlowvu (data) {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return flowvuInstance.post('/flowvus/create', data, config)
  }

  async getFlowvu (flowId) {
    return flowvuInstance.get(`/flowvus/${flowId}`)
  }

  async appendSlidesToExistingFlow (data) {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return flowvuInstance.patch('/flowvus/appendSlide', data, config)
  }

  async appendVideoToExistingFlow (data) {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return flowvuInstance.patch('/flowvus/appendVideo', data, config)
  }
}

export default new FlowVUService()
