import React from 'react'
import { useTranslation } from 'react-i18next'
import { theme } from '../../../styles/theme.style'

export const Footnote = () => {
  const { t } = useTranslation()
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        bottom: 0,
        position: 'absolute',
        left: '20%',
        zIndex: '-999'
      }}
    >
      <div style={{ color: 'white', fontSize: 12 }}>
        © {new Date().getFullYear()} {theme.COMPANY_NAME}
      </div>
      <div style={{ color: 'white', marginBottom: 10, fontSize: 12 }}>
        {t('All Rights Reserved')}
      </div>
    </div>
  )
}
