import React from 'react'
import { useTranslation } from 'react-i18next'
import { CustomSelect } from '../../Shared/CustomSelect/CustomSelect.component'
import Cookies from 'universal-cookie'
import styles from '../Settings.module.scss'

const ChangeLanguageRow = ({ showLabel = true }) => {
  const { t, i18n } = useTranslation()
  const [lang, setLang] = React.useState(i18n.resolvedLanguage)

  const changeLang = (event) => {
    const selectedLang = event.target.value
    i18n.changeLanguage(selectedLang, (err) => {
      if (err) return console.log('something went wrong loading', err)
      setLang(selectedLang)
      const cookies = new Cookies()
      cookies.set('i18next', selectedLang, {
        path: '/',
        sameSite: 'strict',
        secure: true
      })
    })
  }

  return (
    <div className={styles.content__row}>
      {showLabel && (
        <div className={styles.content__row__title}>{t('Change Language')}</div>
      )}
      <CustomSelect
        currentItem={lang}
        handleItemChange={changeLang}
        allItems={{
          en: 'English',
          fr: 'Française',
          ar: 'العربية',
          ch: '中文 (简体)'
        }}
      />
    </div>
  )
}

export default ChangeLanguageRow
