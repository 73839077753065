import React from 'react'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import './BrandedButton.style.scss'

export const BrandedButton = ({ onClick, buttonLabel, disabled = false }) => {
  const { t } = useTranslation()
  return (
    <div className="brandedButton">
      <Button
        className="buttonColor"
        color="primary"
        onClick={onClick}
        disabled={disabled}
      >
        {t(buttonLabel)}
      </Button>
    </div>
  )
}
